// Theme constants
export const THEME = {
    DAY: {
      TITLE: '☀️ good day 😎',
      MODE: 'day',
      WAVE_COLOR: 0xDDDADF,
      BACKGROUND_COLOR: 0xffffff,
      COLOR_SCHEME: 'light',
      TOOLBAR_COLOR: '#ffffff'  // Define toolbar colors directly here
    },
    NIGHT: {
      TITLE: '🌙 good evening ✨',
      MODE: 'night',
      WAVE_COLOR: 0x3D3D3D,
      BACKGROUND_COLOR: 0x000000,
      COLOR_SCHEME: 'dark',
      TOOLBAR_COLOR: '#000000'  // Define toolbar colors directly here
    }
  };
  
  // Theme management functions
  export const getSystemThemePreference = () => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').matches) {
      return !window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return true; // Default to day theme if system preference is not available
  };
  
  export const updateDocumentTheme = (isDaytime) => {
    const theme = isDaytime ? THEME.DAY : THEME.NIGHT;
    
    // Update document title
    document.title = theme.TITLE;
    
    // Update body classes
    document.body.classList.toggle('day', isDaytime);
    document.body.classList.toggle('night', !isDaytime);
    
    // Update Safari toolbar
    updateSafariToolbar();
  };
  
  export const updateSafariToolbar = () => {
    const isDark = document.body.classList.contains('night');
    const color = isDark ? THEME.NIGHT.TOOLBAR_COLOR : THEME.DAY.TOOLBAR_COLOR;
    document.getElementById('theme-color')?.setAttribute('content', color);
  };