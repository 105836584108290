import React, { useState, useEffect } from 'react';
import './PersonalInfo.css';

const PersonalInfo = () => {
  const [nameIndex, setNameIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const names = ["Constance", "Connie", "依然"];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      
      setTimeout(() => {
        setNameIndex((prevIndex) => (prevIndex + 1) % names.length);
        setIsTransitioning(false);
      }, 500); // Half of our total transition time
      
    }, 2500);
    
    return () => clearInterval(interval);
  }, [names.length]);

  const timelineData = [
    { 
      year: 'Late 90s', 
      event: 'Jacked into the world wide web',
      org: null
    },
    { 
      year: 'Early 2010s', 
      event: 'Graphic design, multimedia studies',
      org: 'Northeastern University'
    },
    { 
      year: '1 year', 
      event: 'Frontend development',
      org: 'The Boston Globe'
    },
    { 
      year: '2 years', 
      event: 'Design, frontend development',
      org: 'Airbo'
    },
    { 
      year: '3 years', 
      event: 'Design, development, management',
      org: 'thoughtbot'
    },
    { 
      year: '6+ years', 
      event: 'Product design',
      org: 'Iterable'
    }
  ];

  const contactInfo = [
    { medium: 'Github', link: 'https://github.com/conchan', rel: 'me' },
    { medium: 'Dribbble', link: 'https://dribbble.com/conchan' },
    { medium: 'LinkedIn', link: 'https://www.linkedin.com/in/conchan/' },
    { medium: 'Mastodon', link: 'https://mastodon.social/@conchan', rel: 'me' },
    { medium: 'Email', link: 'mailto:hey@conchan.site' },
    { medium: 'PGP key', link: 'pgp.txt', newTab: true }
  ];

  return (
    <div
      className="personal-info"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1 className="name">
        <span className={`namechanger ${isTransitioning ? 'transitioning' : ''}`}>
          {names[nameIndex]}
        </span> <br/>Chan
      </h1>
      <h3 className="description">A designer of this digital world</h3>
      <ul className="timeline">
        {timelineData.map((item, index) => (
          <li
            className="timeline-item"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <article className="experience">
              <strong className="duration"><time>{item.year}:</time></strong>
              <span className="responsibilities">{item.event}</span>
              {item.org && <span className="org">{item.org}</span>}
            </article>
          </li>
        ))}
      </ul>

      <ul className="contact-info">
        {contactInfo.map((item, index) => (
          <li
            className="contact-method"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <a 
              href={item.link}
              target={item.newTab ? "_blank" : undefined}
              rel={item.newTab ? "noopener noreferrer" : item.rel}
            >
              {item.medium}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PersonalInfo;