import React, { useState, useEffect } from 'react';
import { updateMood } from './StockDataService';
import WaveVisualization from './WaveVisualization';
import './StockMoodVisualization.css';

const MarketInfo = ({ mood }) => {
  if (!mood || !mood.price === undefined || !mood.previousClose === undefined) return null;

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Unknown';
    try {
      const date = new Date(timestamp);
      
      // Add a console.log for debugging
      console.log('Raw timestamp:', timestamp);
      console.log('Parsed date:', date);
      
      const timeString = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
      return timeString;
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  // Format the current price
  const currentPrice = mood.price.toFixed(2);
  
  // Calculate the trend based on current price vs previous close
  const priceChange = mood.price - mood.previousClose;
  const percentChange = ((priceChange / mood.previousClose) * 100).toFixed(2);
  const trend = priceChange >= 0 ? 'positive' : 'negative';

  return (
    <div className="market-info">
      <p className={`market-change ${trend}`}>
        Last check: ${currentPrice} (<span className="percentChangeAmount">{percentChange}%</span>), {formatLastUpdated(mood.lastUpdated)}
      </p>
    </div>
  );
};

const StockMoodVisualization = ({ isDaytime }) => {
  const [mood, setMood] = useState({
    waveHeight: 0.5,
    waveFrequency: 0.5,
    marketPerformance: 0,
    price: 0,
    previousClose: 0,
    lastUpdated: null
  });

  useEffect(() => {
    const getData = async () => {
      try {
        console.log('Fetching initial stock data at:', new Date().toISOString());
        const response = await fetch('/stockData.json', {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        });
        
        console.log('Response status:', response.status);
        console.log('Response headers:', [...response.headers.entries()]);
        
        const data = await response.json();
        console.log('Received stock data:', data);
        
        if (data) {
          const newMood = {
            ...updateMood(data),
            price: data.price,
            previousClose: data.previousClose,
            lastUpdated: data.lastUpdated
          };
          console.log('Setting initial mood:', newMood);
          setMood(newMood);
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    getData(); // Single fetch on component mount
  }, []);

  return (
    <div className="visualization-container">
      <WaveVisualization mood={mood} isDaytime={isDaytime} />
      <MarketInfo mood={mood} />
    </div>
  );
};

export default StockMoodVisualization;
