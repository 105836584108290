// const SYMBOL = '^GSPC'; // S&P 500 index - Not used in this file

export const fetchStockData = async () => {
  try {
    const response = await fetch('/stockData.json');
    const data = await response.json();

    return {
      price: data.price,
      previousClose: data.previousClose,
      volume: data.volume,
      lastUpdated: data.lastUpdated,
      marketPerformance: data.marketPerformance || data.percentChange  // Handle both field names for backward compatibility
    };

  } catch (error) {
    console.error('Error fetching stock data:', error);
    return null;
  }
};

export const updateMood = (data) => {
  console.log('updateMood called with data:', data);
  
  if (!data) {
    console.log('No data provided, returning default mood');
    return {
      waveHeight: 0.5,
      waveFrequency: 0.5,
      marketPerformance: 0,
      lastUpdated: null
    };
  }

  const percentChange = (data.price - data.previousClose) / data.previousClose;
  const maxVolume = 10000000000;

  const waveHeight = Math.min(1, Math.max(0.2, Math.abs(percentChange) * 20));
  const waveFrequency = Math.min(1, Math.max(0.2, data.volume / maxVolume));

  const mood = {
    waveHeight,
    waveFrequency,
    marketPerformance: percentChange,
    lastUpdated: data.lastUpdated
  };
  
  console.log('Calculated mood:', mood);
  return mood;
};
