import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import * as THREE from 'three';
import { THEME } from './themeManager';

// Add this near the top of your file, outside the component
const isTouchDevice = () => {
  return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
};

// Configuration object update
const CONFIG = {
  // Camera settings
  cameraFOV: 105,
  cameraNear: 0.1,
  cameraFar: 1000,
  cameraPosition: { x: 0, y: 0.2, z: 3 },

  // Lighting
  ambientLightIntensity: 0.35,

  // Wave geometry
  waveSize: {
    aspectRatio: 1/1,  // Set desired aspect ratio (width:height)
    coverageMultiplier: 1.5  // How much bigger than viewport (1.5 = 150%)
  },
  segmentsRange: {
    desktop: { min: 30, max: 40 },
    mobile: { min: 15, max: 20 }  // Lower values for better mobile performance
  },
  waveRotationX: -Math.PI / 24,

  // Appearance
  opacity: 0.5,

  // Animation
  dampingFactor: 0.5,
  propagationSpeed: 0.05,
  maxDisplacement: 1.5,

  // Mouse interaction
  maxInteractionDistance: 2.5,
  interactionScaleFactor: 1.2,
  rippleIntensity: 0.08,
  rippleFrequency: 8,
  ripplePhaseMultiplier: 4,

  // Performance
  raycastInterval: {
    touch: 10, // Higher interval for touch devices
    desktop: 2  // Existing interval for desktop
  },
};

const WaveVisualization = ({ mood, isDaytime }) => {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const waveRef = useRef(null);
  const timeRef = useRef(0);
  const raycasterRef = useRef(new THREE.Raycaster());
  const mouseRef = useRef(new THREE.Vector2());
  const intersectionPointRef = useRef(null);
  const previousWaveStateRef = useRef(null);
  const frameCountRef = useRef(0);
  const lastTimeRef = useRef(0);
  const raycastIntervalRef = useRef(isTouchDevice() ? CONFIG.raycastInterval.touch : CONFIG.raycastInterval.desktop);

  const currentTheme = useMemo(() => isDaytime ? THEME.DAY : THEME.NIGHT, [isDaytime]);

  const animateRef = useRef((currentTime) => {
    requestAnimationFrame(animateRef.current);

    const wave = waveRef.current;
    if (!wave) return;

    const deltaTime = currentTime - lastTimeRef.current;
    lastTimeRef.current = currentTime;

    const { waveHeight = 0.5, waveFrequency = 0.5, marketPerformance = 0 } = mood;
    const animationSpeed = (Math.abs(marketPerformance) * 0.01 + 0.001) * (deltaTime / 16.67); // Frame rate independent
    timeRef.current += animationSpeed;

    const positions = wave.geometry.attributes.position.array;
    const originalPositions = wave.geometry.attributes.position.originalArray || 
      positions.slice();
    wave.geometry.attributes.position.originalArray = originalPositions;

    // Optimize raycasting by reducing frequency
    frameCountRef.current++;
    if (frameCountRef.current % raycastIntervalRef.current === 0) {
      raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);
      const intersects = raycasterRef.current.intersectObject(wave);

      if (intersects.length > 0) {
        intersectionPointRef.current = intersects[0].point;
      } else {
        intersectionPointRef.current = null;
      }
    }

    // Wave animation with momentum and propagation
    for (let i = 0; i < positions.length; i += 3) {
      const x = originalPositions[i];
      const y = originalPositions[i + 1];
      
      // Base wave motion
      let z = Math.sin(x * waveFrequency + timeRef.current) * 
              Math.cos(y * waveFrequency + timeRef.current) * 
              waveHeight;

      // Add propagation effect
      z += (positions[i + 2] - previousWaveStateRef.current[i + 2]) * CONFIG.propagationSpeed;

      // Mouse interaction
      if (intersectionPointRef.current) {
        const distance = Math.sqrt(
          Math.pow(x - intersectionPointRef.current.x, 2) + 
          Math.pow(y - intersectionPointRef.current.y, 2)
        );

        if (distance < CONFIG.maxInteractionDistance) {
          const scale = 1 + (1 - distance / CONFIG.maxInteractionDistance) * CONFIG.interactionScaleFactor;
          z *= scale;

          const ripplePhase = timeRef.current * CONFIG.ripplePhaseMultiplier;
          z += Math.sin(distance * CONFIG.rippleFrequency - ripplePhase) * 
               CONFIG.rippleIntensity * 
               (1 - distance / CONFIG.maxInteractionDistance);
        }
      }

      // Apply damping
      z = z * CONFIG.dampingFactor + previousWaveStateRef.current[i + 2] * (1 - CONFIG.dampingFactor);

      // Limit maximum displacement
      z = Math.max(Math.min(z, CONFIG.maxDisplacement), -CONFIG.maxDisplacement);

      // Update position
      positions[i + 2] = z;
      previousWaveStateRef.current[i + 2] = z;
    }

    wave.geometry.attributes.position.needsUpdate = true;

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  });

  const onMouseMove = useCallback((event) => {
    mouseRef.current.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouseRef.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }, []);

  const onTouchMove = useCallback((event) => {
    // Remove the preventDefault call
    const touch = event.touches[0];
    const rect = event.target.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    mouseRef.current.x = (x / rect.width) * 2 - 1;
    mouseRef.current.y = -(y / rect.height) * 2 + 1;
  }, []);

  const onWindowResize = useCallback(() => {
    if (cameraRef.current && rendererRef.current && waveRef.current) {
      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      
      // Recalculate wave size maintaining aspect ratio
      const fov = CONFIG.cameraFOV * (Math.PI / 180);
      const viewHeight = 2 * Math.tan(fov / 2) * Math.abs(CONFIG.cameraPosition.z);
      const viewWidth = viewHeight * (window.innerWidth / window.innerHeight);
      
      let waveWidth, waveHeight;
      const viewportRatio = viewWidth / viewHeight;
      
      if (viewportRatio > CONFIG.waveSize.aspectRatio) {
        waveWidth = viewWidth * CONFIG.waveSize.coverageMultiplier;
        waveHeight = waveWidth / CONFIG.waveSize.aspectRatio;
      } else {
        waveHeight = viewHeight * CONFIG.waveSize.coverageMultiplier;
        waveWidth = waveHeight * CONFIG.waveSize.aspectRatio;
      }
      
      // Store original dimensions for reference
      if (!waveRef.current.geometry.parameters) {
        waveRef.current.geometry.parameters = {
          width: waveWidth,
          height: waveHeight
        };
      }
      
      waveRef.current.scale.set(
        waveWidth / waveRef.current.geometry.parameters.width,
        waveHeight / waveRef.current.geometry.parameters.height,
        1
      );
    }
  }, []);

  const createWaveRef = useRef(() => {
    console.log('Creating wave');
    const isTouch = isTouchDevice();
    const segmentRange = isTouch ? CONFIG.segmentsRange.mobile : CONFIG.segmentsRange.desktop;
    const segments = Math.floor(Math.random() * (segmentRange.max - segmentRange.min + 1)) + segmentRange.min;
    
    // Calculate base dimensions from camera FOV
    const fov = CONFIG.cameraFOV * (Math.PI / 180);
    const viewHeight = 2 * Math.tan(fov / 2) * Math.abs(CONFIG.cameraPosition.z);
    const viewWidth = viewHeight * (window.innerWidth / window.innerHeight);
    
    // Calculate wave dimensions maintaining aspect ratio while covering viewport
    let waveWidth, waveHeight;
    const viewportRatio = viewWidth / viewHeight;
    
    if (viewportRatio > CONFIG.waveSize.aspectRatio) {
      // Viewport is wider than target ratio - match width
      waveWidth = viewWidth * CONFIG.waveSize.coverageMultiplier;
      waveHeight = waveWidth / CONFIG.waveSize.aspectRatio;
    } else {
      // Viewport is taller than target ratio - match height
      waveHeight = viewHeight * CONFIG.waveSize.coverageMultiplier;
      waveWidth = waveHeight * CONFIG.waveSize.aspectRatio;
    }
    
    const geometry = new THREE.PlaneGeometry(waveWidth, waveHeight, segments, segments);
    const material = new THREE.MeshBasicMaterial({
      color: currentTheme.WAVE_COLOR,
      wireframe: true,
      transparent: true,
      opacity: CONFIG.opacity,
      depthWrite: false,
      side: THREE.DoubleSide
    });
    const wave = new THREE.Mesh(geometry, material);
    wave.rotation.x = CONFIG.waveRotationX;
    sceneRef.current.add(wave);
    waveRef.current = wave;
    if (!previousWaveStateRef.current) {
      previousWaveStateRef.current = new Float32Array(geometry.attributes.position.count * 3);
    }
  });

  const initThreeJS = useCallback(() => {
    console.log('Initializing Three.js');
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      CONFIG.cameraFOV, 
      window.innerWidth / window.innerHeight, 
      CONFIG.cameraNear, 
      CONFIG.cameraFar
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    camera.position.set(
      CONFIG.cameraPosition.x, 
      CONFIG.cameraPosition.y, 
      CONFIG.cameraPosition.z
    );
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const ambientLight = new THREE.AmbientLight(0xffffff, CONFIG.ambientLightIntensity);
    scene.add(ambientLight);

    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;

    if (createWaveRef.current) {
      createWaveRef.current();
    }
    if (animateRef.current) {
      animateRef.current(0);
    }

    if (isTouchDevice()) {
      containerRef.current.addEventListener('touchmove', onTouchMove, { passive: true });
    } else {
      window.addEventListener('mousemove', onMouseMove);
    }
    window.addEventListener('resize', onWindowResize);
  }, [onWindowResize, onMouseMove, onTouchMove]);

  const cleanupThreeJS = useCallback(() => {
    console.log('Cleaning up');
    if (rendererRef.current) {
      rendererRef.current.dispose();
    }
    if (sceneRef.current) {
      sceneRef.current.clear();
    }
    if (isTouchDevice()) {
      containerRef.current.removeEventListener('touchmove', onTouchMove);
    } else {
      window.removeEventListener('mousemove', onMouseMove);
    }
    window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize, onMouseMove, onTouchMove]);

  const updateWave = useCallback((newMood) => {
    console.log('Updating wave');
    const wave = waveRef.current;
    wave.material.color.setHex(currentTheme.WAVE_COLOR);
    wave.scale.set(1 + newMood.waveFrequency, 1 + newMood.waveFrequency, 1 + newMood.waveFrequency);
    wave.material.needsUpdate = true;
  }, [currentTheme]);

  const updateBackgroundColor = useCallback(() => {
    if (rendererRef.current) {
      rendererRef.current.setClearColor(currentTheme.BACKGROUND_COLOR);
    }
  }, [currentTheme]);

  useEffect(() => {
    console.log('Component mounted');
    if (containerRef.current && !rendererRef.current) {
      initThreeJS();
    }
    return () => {
      cleanupThreeJS();
    };
  }, [initThreeJS, cleanupThreeJS]);

  useEffect(() => {
    console.log('Mood or daytime updated:', mood, isDaytime);
    if (waveRef.current) {
      updateWave(mood);
    }
    if (rendererRef.current) {
      updateBackgroundColor();
    }
  }, [mood, isDaytime, updateWave, updateBackgroundColor]);

  return (
    <div ref={containerRef} className="visualization-canvas">
      {!rendererRef.current && (
        <span className="loading">Loading...</span>
      )}
    </div>
  );
};

export default WaveVisualization;