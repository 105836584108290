import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import StockMoodVisualization from './StockMoodVisualization';
import PersonalInfo from './PersonalInfo';
import GoatCounter from './GoatCounter';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { getSystemThemePreference, updateDocumentTheme } from './themeManager';

const App = () => {
  const [isDaytime, setIsDaytime] = useState(getSystemThemePreference());

  useEffect(() => {
    // Initial theme setup
    updateDocumentTheme(isDaytime);

    // Set up system theme change listener
    const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const themeChangeHandler = (e) => {
      const isDay = !e.matches;
      setIsDaytime(isDay);
      updateDocumentTheme(isDay);
    };

    colorSchemeQuery.addEventListener('change', themeChangeHandler);

    return () => colorSchemeQuery.removeEventListener('change', themeChangeHandler);
  }, [isDaytime]);

  return (
    <div className="container">
      <StockMoodVisualization isDaytime={isDaytime} />
      <PersonalInfo />
      <GoatCounter />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();

export default App;